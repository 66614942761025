<template>
  <div class="container PablicOffer">

     <h2>Лицензионное соглашение на использование программы «ForPurchases» в мессендже Telegram и на сайте forpurchases.ru</h2>

     <p>«01» марта 2023г.</p>

      <p>Перед использованием программы, пожалуйста, ознакомьтесь с условиями нижеследующего лицензионного соглашения.</p>
      <p>Любое использование Вами программы означает полное и безоговорочное принятие Вами условий настоящего лицензионного соглашения. Незнание и/или неознакомление с условиями настоящего Соглашения не освобождает Пользователя от ответственности за несоблюдение настоящего Соглашения.</p>
      <p>Если Вы не принимаете условия лицензионного соглашения в полном объёме, Вы не имеете права использовать программу в каких-либо целях.</p>
     
      <h4>1. Общие положения</h4>
      <p>1.1. Настоящее Лицензионное соглашение (далее — «Лицензия») устанавливает условия использования программы «ForPurchases» (далее — «Программа») и заключено между любым лицом, использующим Программу (далее — «Пользователь») и Индивидуальным предпринимателем Быковым Дмитрием Борисовичем ОГРНИП 309602728800021, представленным в лице Администрации онлайн сервиса, расположенного в сети интернет по адресу: https://forpurchases.ru и в мессендже Telegram по адресу: https://t.me/for_pur_bot (далее — «forpurchases»), являющимся правообладателем исключительного права на Программу («Правообладатель»).
      </p>
      <p>1.2. Пользователь имеет право использовать Программу следующими способами: регистрация в Программе, а также использование всех доступных функций Программы, предоставленных Пользователю. Используя Программу в рамках настоящего Лицензионного соглашения, Пользователь выражает свое полное и безоговорочное согласие со всеми условиями Лицензии.</p>
      <p>1.3. Использование Программы разрешается только на условиях настоящей Лицензии. Если Пользователь не принимает условия Лицензии в полном объёме, Пользователь не имеет права использовать Программу в каких-либо целях. Использование Программы с нарушением (невыполнением) какого-либо из условий Лицензии запрещено.</p>
      <p>1.4. Использование Программы на условиях и способами, не предусмотренными настоящей Лицензией, возможно только на основании отдельного соглашения с Правообладателем. За пользование отдельными функциями Программы может взиматься плата.</p>
      <p>1.5. Программа предоставляет функциональную возможность создавать Заявки для предоставления доступа к онлайн сервисам находящимся за пределами Российской Федерации на возмездной основе в порядке, предусмотренном п.4 настоящей Лицензии. Функциональные возможности Программы могут быть ограничены в зависимости от устройства Пользователя.</p>
      <p>1.6. К настоящей Лицензии и всем отношениям, связанным с использованием Программы, подлежит применению право Российской Федерации и любые претензии или иски, вытекающие из настоящей Лицензии или использования Программы, должны быть поданы и рассмотрены в суде по месту нахождения Правообладателя.</p>
      
      <h4>2. Ограничения</h4>
      <p>2.1. Правообладатель оставляет за собой право устанавливать любые правила, лимиты и ограничения (технические, юридические, организационные или иные) на использование Программы в целом и Ограниченного функционала, и может менять их по собственному усмотрению. Указанные правила, лимиты и ограничения могут быть различными для различных категорий Пользователей.</p>

      <h4>3. Условия использования отдельных функций Программы</h4>
      <p>3.1. Выполнение функций Программы возможно только при наличии доступа к «forpurchases» в сети Интернет. Пользователь самостоятельно получает и оплачивает в случае необходимости такой доступ на условиях и по тарифам своего оператора связи или провайдера доступа к сети Интернет.</p>
      <p>3.2. Правообладатель оставляет за собой право на свое усмотрение ограничить доступ Пользователя к Программе (или к определенным функциям Программы, если это возможно технологически) при нарушении настоящей Лицензии, либо применить к Пользователю иные меры с целью обеспечения требований законодательства или прав и законных интересов третьих лиц.</p>

      <h4>4. Вознаграждение за использование</h4>
      <p>4.1. Общие условия</p>
      <p>4.1.1. Правообладатель предоставляет Пользователю за вознаграждение доступ к функционалу Заявок. Одна лицензия действует в рамках функционала одного экземляра Заявки.</p>
      <p>4.1.2. Доступ к функционалу Заявок предоставляется Правообладателем с момента получения вознаграждения от Пользователя</p>

      <p>4.2. Условия оплаты вознаграждения</p>
      <p>4.2.1. Оплата вознаграждения Правообладателю производится Пользователем в порядке предоплаты.</p>
      <p>4.2.2. Сведения о размере вознаграждения за Лицензию, способах его внесения, а также иная информация указываются в соответствующем разделе в интерфейсе Программы. Данные сведения являются частью настоящей Лицензии.</p>

      <h4>5. Ответственность по Лицензии</h4>
      <p>5.1. Программа (включая Данные) предоставляется на условиях «как есть» (as is). Правообладатель не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы Программы или отдельных её компонентов и/или функций, соответствия Программы конкретным целям и ожиданиям Пользователя, не гарантирует достоверность, точность, полноту и своевременность Данных, а также не предоставляет никаких иных гарантий, прямо не указанных в настоящей Лицензии.</p>
      <p>5.2. Правообладатель не несет ответственности за какие-либо прямые или косвенные последствия какого-либо использования или невозможности использования Программы и/или ущерб, причиненный Пользователю и/или третьим сторонам в результате какого-либо использования, неиспользования или невозможности использования Программы или отдельных её компонентов и/или функций, в том числе из-за возможных ошибок или сбоев в работе Программы, за исключением случаев, прямо предусмотренных законодательством.</p>
      <p>5.3. Пользователь несет ответственность за:</p>
      <p>5.3.1. актуальность, достоверность, законность, отсутствие претензий третьих лиц в отношении информации, которую Пользователь размещает с помощью Программы;</p>
      <p>5.3.2. собственные действия в Программе и их соответствие действующему законодательству РФ.</p>
      <p>5.4. Правообладатель не несет ответственности:</p>
      <p>5.4.1. за специализированные ресурсы и качество каналов сетей связи общего пользования, посредством которых предоставляется доступ к Программе, а также вызванные перебоями в предоставлении услуг третьими лицами и (или) центров обработки и хранения данных;</p>
      <p>5.4.2. за возможные сбои и перерывы в работе Программы, прекращение его функционирования и вызванную ими потерю Контента и/или любой другой информации, в том числе за невозможность использования Программу на определенной территории;</p>
      <p>5.4.3. за любые виды ущерба или убытков, произошедшие вследствие использования Пользователем Программы;</p>
      <p>5.4.4. за поломки или другие неполадки компьютера, мобильного устройства, любого другого устройства Пользователя, возникшие во время использования Программы;</p>
      <p>5.4.5. ошибки в работе аппаратно-программного комплекса системы платежей. В случае не зачисления денежных средств на счет Правообладателя из-за ошибки, обязанности по возврату денежных средств Пользователю лежат на провайдере электронной системы платежей.</p>
      <p>5.4.6. В случае если Пользователь без письменного согласия/поручения Правооблодателя скопировал, внес изменения, подготовил производные материалы, декомпилировал, дизассемблировал Программу или совершил любые другие попытки получения доступа к исходному коду, каким-либо иным способом изменил Программу, Правообладатель вправе потребовать от Пользователя выплатить штраф в размере 1 000 000 (один миллион) рублей за каждый случай такого нарушения.</p>
      <p>5.4.7. В случае предъявления Правообладателю претензий, требований и/или исков о нарушении прав третьих лиц, возникших по вине Пользователя, Пользователь принимает на себя обязательства по их урегулированию и возмещению ущерба Правообладателю в случае его возникновения.</p>
      <p>5.4.8. Ответственность Правообладателя перед пользователем не может превышать размер вознаграждения, перечисленного Пользователем за Лицензию, в рамках которой произошло нарушение.</p>

      <h4>6. Обновления/новые версии Программы и стороннее программное обеспечение</h4>
      <p>6.1. Действие настоящей Лицензии распространяется на все последующие обновления/новые версии Программы. Соглашаясь с установкой обновления/новой версии Программы, Пользователь принимает условия настоящей Лицензии для соответствующих обновлений/новых версий Программы, если обновление/установка новой версии Программы не сопровождается иным Лицензионным соглашением.</p>

      <h4>7. Обработка персональных данных</h4>
      <p>7.1. Принимая условия настоящего Соглашения, Пользователь в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года, действуя свободно, своей волей и в своем интересе, выражает свое согласие на: предоставление своих персональных данных, включающих адрес электронной почты, номер контактного телефона, данные аккаунта в мессенджере Telegram для их обработки Правообладателем (Оператором).</p>
      <p>7.2. Правообладатель обязуется принимать все необходимые меры для защиты указанных выше персональных данных от неправомерного доступа или раскрытия.</p>
      <p>7.3. Правообладатель осуществляет следующие действия в отношении обработки персональных данных: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление, уничтожение, передачу третьему лицу (банк-экваер) сведений необходимых для осуществления списания в счёт оплаты Лицензий.</p>
      <p>7.4. Пользователь может отозвать согласие на обработку персональных данных, обратившись в поддержку по электронной почте: {{ $t("contacts.mail.address") }}</p>

      <h4>8. Изменения условий настоящей Лицензии</h4>
      <p>8.1. Настоящая Лицензия может изменяться Правообладателем в одностороннем порядке. Уведомление Пользователя о внесенных изменениях в условия настоящей Лицензии публикуется на странице: https://forpurchases.ru/terms. Указанные изменения в условиях лицензионного соглашения вступают в силу с даты их публикации, если иное не оговорено в соответствующей публикации.</p>
     
      <h4>9. Реквизиты Правообладателя</h4>
      <p>
          Индивидуальный предприниматель Быков Дмитрий Борисович
        <br>г. Псков 
        <br>ИНН: 602717767339
        <br>Р/с: 40802810202890002742
        <br>ПАО АКБ "АВАНГАРД"
        <br>К/с: 30101810000000000201, БИК 044525201
        <br>Тел. 8 911 847 23 30
        <br>E-mail: {{ $t("contacts.mail.address") }}</p>
  </div>
</template>

<script>
  export default {
    name: "PablicOffer",
  };
</script>

<style lang="scss" scoped>
.PablicOffer{
  padding-top: 10%;
}
</style>
